import showdown from "showdown";
import showdownHighlight from "showdown-highlight";


class MarkdownHandler {
  // showdown options documented at: https://github.com/showdownjs/showdown#valid-options
  converter = new showdown.Converter({
    tables: true, tasklists: true, smoothLivePreview: true, splitAdjacentBlockquotes: true, simpleLineBreaks: false,
    extensions: [
      showdownHighlight({ pre: true })
    ]
  });

  constructor() {
    this.converter.setFlavor('github');
  }

  doMarkdownOnLoadEvents() {
    $('#editor').on('click', (event) => this.openTab(event, 'editor-content'))
    $('#preview').on('click', (event) => this.previewMarkdown(event))

    const toMarkdown = this.toMarkdown.bind(this)
    // test for elements with [x-convert-markdown] and set their .innerHTML
    $('[x-convert-markdown]').each(function () {
      // use innerText to prevent chars like > and & being escaped.
      this.innerHTML = toMarkdown(this.innerText)
      // prevent triggering this again (e.g., because of turbo)
      this.removeAttribute('x-convert-markdown')
    })
  }

  openTab(event, tabName) {
    const x = Array.from(document.getElementsByClassName("content-tab"));
    x.map(el => el.style.display = "none");
    const tabLinks = Array.from(document.getElementsByClassName("tab"));
    tabLinks.map(el => el.classList.remove("is-active"));
    document.getElementById(tabName).style.display = "block";
    event.currentTarget.classList.add("is-active");
  }

  // if we need this to be globally available we can do so like this:
  // window.toMarkdown = this.toMarkdown.bind(this);
  toMarkdown(text) {
    return this.converter.makeHtml(text);
  }

  previewMarkdown(event){
    const raw = document.getElementById('node_body').value;
    const data = {
      parent_id: document.getElementById('node_parent_id').value,
      body: raw
    }
    Rails.ajax({
      type: "POST",
      url: "/markdown",
      dataType: "application/json",
      data: new URLSearchParams(data).toString()
    })
    this.openTab(event, "preview-content")
  }
}

// singleton instance
const markdownHandler = new MarkdownHandler()

$(window).on("load", () => markdownHandler.doMarkdownOnLoadEvents())
$(window).on("turbo:load", () => markdownHandler.doMarkdownOnLoadEvents())
