/*
 * # Tabs using data attrs
 *
 * ## Usage
 *
 * You should add data attrs to three types of elements:
 *
 * 1. things you click to change tab (buttons, the "tabs" themselves)
 *    - currently .is-active is only set on the one you click, but you could have multiple redundant buttons.
 * 2. tab content
 *    - you can have more than one thing for this, like many elements that appear when someone clicks the tab
 * 3. an element that is a parent of all tabs and tab-content
 *
 * The root element MUST be a parent to _all_ tab elements that you want to interact with.
 *
 * set `data-tab-group=some_name` on the root element.
 *
 * set `data-tab=this_tab_id` on the tab buttons (the tab id is how content is filtered for show/hide)
 *
 * set `data-tab-content` and `data-tab-id=this_tab_id` on tab content elements.
 *
 * The .is-active class is set on active tabs.
 *
 * that's it.
 *
 */

const tabEventHandlerSetup = () => {

    $("[data-tab]").on("click", (event) => {
        const el = event.currentTarget;
        const tabId = el.dataset.tab;
        const tabGroupEl = $(el).parents("[data-tab-group]")[0];
        const tabGroup = tabGroupEl.dataset.tabGroup;
        const lastTab = $(`[data-tab-group=${tabGroup}] [data-tab-content]:not(.hidden)`)[0].dataset.tabId;
        const allTabs = $(`[data-tab-group=${tabGroup}] [data-tab-content]`);
        const newActiveTabEls = $(`[data-tab-group=${tabGroup}] [data-tab-content][data-tab-id=${tabId}]`);
        const allTabClickers = $(`[data-tab-group=${tabGroup}] [data-tab]`);
        const activeTabClickers = $(`[data-tab-group=${tabGroup}] [data-tab=${tabId}]`);

        allTabs.toArray().forEach(t => t.classList.add("hidden"));
        newActiveTabEls.toArray().forEach(t => t.classList.remove("hidden"));
        allTabClickers.toArray().forEach(tabBtn => tabBtn.classList.remove("is-active"));
        activeTabClickers.toArray().forEach(tc => tc.classList.add("is-active"));

        const ltSlug = lastTab.replaceAll('-', '_');
        let newPathname;
        const newFragment = tabId.replaceAll('-', '_');
        if (window.location.pathname.endsWith(`/${ltSlug}`)) {
            newPathname = window.location.pathname.replace(new RegExp(`${ltSlug}$`), newFragment);
        } else {
            newPathname = [window.location.pathname, newFragment].join('/')
        }
        history.pushState({}, '', newPathname)
    })
}

Object.values({}).filter(v => v == null).length == 0

$(window).on("load", tabEventHandlerSetup)
$(window).on("turbo:load", tabEventHandlerSetup)
