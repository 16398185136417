class addFields {
    // This executes when the function is instantiated.
    constructor() {
        this.iterateLinks()
    }

    iterateLinks() {
        $(".add_fields").on("click", evt => this.handleClick(evt.currentTarget, evt))
        // document.addEventListener('click', e => {
        //     console.debug(`addFields click listener triggered.`, e)
        //     if (e.target && e.target.classList.contains('add_fields')) {
        //         this.handleClick(e.target, e)
        //     }
        // })
    }

    handleClick(link, e) {
        // Stop the function from executing if a link or event were not passed into the function.
        if (!link) return
        // Prevent the browser from following the URL.
        e.preventDefault()
        // Save a unique timestamp to ensure the key of the associated array is unique.
        let time = new Date().getTime()
        // Save the data id attribute into a variable. This corresponds to `new_object.object_id`.
        let linkId = link.dataset.id
        // Create a new regular expression needed to find any instance of the `new_object.object_id` used in the fields data attribute if there's a value in `linkId`.
        let regexp = linkId ? new RegExp(linkId, 'g') : null
        // Replace all instances of the `new_object.object_id` with `time`, and save markup into a variable if there's a value in `regexp`.
        let newFields = regexp ? link.dataset.fields.replace(regexp, time) : null
        // Add the new markup to the form if there are fields to add.
        newFields ? this.handleInsert(link, newFields) : null;
        // link.insertAdjacentHTML('beforebegin', newFields) : null
    }

    handleInsert(link, newFields) {
        console.assert(link.dataset.insertTarget, "missing: link.dataset.insertTarget", link.dataset)
        const insertTg = link.dataset.insertTarget
        // legal values for insertPosition: "afterbegin", "afterend", "beforebegin", "beforeend"
        // https://www.w3schools.com/jsref/met_node_insertadjacenthtml.asp
        const insertPosition = link.dataset.insertPosition
        if (insertTg) {
            console.debug(`[addFields.handleInsert] Inserting fields '${insertPosition}' for element '${insertTg}'`)
            $(insertTg)[0].insertAdjacentHTML(insertPosition || 'beforeend', newFields)
        } else {
            // default
            link.insertAdjacentHTML('beforebegin', newFields);
        }
    }
}

// window.addEventListener('load', () => new addFields())
window.addEventListener('turbo:load', () => new addFields())
// Wait for turbolinks to load, otherwise `document.querySelectorAll()` won't work
// NB: couldn't get turbolinks event to work :/
// -> `document.addEventListener('turbolinks:load', () => new addFields())`
