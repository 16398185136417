function getElsForCfgKey(cfgKey) {
    const hidden_el = $(`input[type=hidden]#forum_config_${cfgKey}`)[0];
    const picker_el = $(`input[type=color]#${cfgKey}_picker`)[0];
    const hex_el = $(`input[type=text]#${cfgKey}_hex`)[0];
    const default_el = $(`input[type=color]#${cfgKey}_default`)[0];
    return { hidden_el, picker_el, hex_el, default_el };
}

export function _forumConfigSyncColors(el, cfgKey) {
    const { hidden_el, picker_el, hex_el } = getElsForCfgKey(cfgKey);
    const form_els = [hidden_el, picker_el, hex_el];
    const newVal = el.value;
    // test validity of hex value, otherwise do nothing
    if (/^#[0-9a-fA-F]{6}$/.test(newVal)) {
        form_els.filter(_el => _el.id != el.id).forEach(_el => _el.value = newVal);
    }
}

window._forumConfigSyncColors = _forumConfigSyncColors;

window._resetColorFieldToDefault = (cfgKey) => {
    const form_els = getElsForCfgKey(cfgKey);
    const default_el = form_els.default_el;
    Object.keys(form_els).map(k => form_els[k]).filter(el => el != default_el).forEach(el => el.value = default_el.value);
}
