window.addEventListener('load', () => {
    const handler = e => {
        const form = $(e.target).parents("form");
        if (form.length) {
            if (e.key === "Enter" && e.ctrlKey) {
                form.trigger("submit");
            }
        }
    };
    $(document).on("keydown", handler)
})
