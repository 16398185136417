// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import * as Turbo from "@hotwired/turbo";
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "@primer/octicons/build/build.css"
import Alpine from "alpinejs"
import "channels"
import "stylesheets/application.scss"
import "controllers"

window.Rails = Rails;
Rails.start()
ActiveStorage.start()
window.Alpine = Alpine;
window.addEventListener('load', () => Alpine.start());
require("jquery")

import './adm';
import './tabs';
require('./nested-forms/addFields');
require('./nested-forms/removeFields');
require('./ctrlEnter');
require('./markdown');
require('./categoryTags');
