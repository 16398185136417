/*

The objects stored in selectedTags are of the format:
{
  id: id of the tagdecl if it exists (i.e. if you're editing a post with existing cat tags)
  targetId: id of the category tag
  text: name of the category tag
  tag: establishing the relationship of the tagdecl, in this case: category
  _destroy: boolean that is passed to the backend to handle deletions of existing tags
}
These objects represent TagDecls and are designed so that processing is minimal in the back end.
TagDecl attrs include: id, target_id, tag, and _destroy.
The text attr in this case is used for rendering the ui components.

*/

window.categoryTags = function (selectedCtds, cts) {
  return {
    selectedId: "",
    selectedTags: selectedCtds,
    categoryTags: cts,
    newTag: "",

    init() {
      // add all the existing hidden inputs
      this.selectedTags.forEach((el, i) => addHiddenTagInputs(i, el))
    },

    addExistingTag(selectedCategoryEl) {
      const text = selectedCategoryEl.options[selectedCategoryEl.selectedIndex].text
      if (text === "Select a tag") return

      var tagObj = this.selectedTags.find(t => t.text === text)
      if (tagObj != null) {
        // if the _destroy attr is "1", the tag has been removed before
        // otherwise the user is selecting a tag that is alr selected, which in that case, do nothing
        if (tagObj._destroy == "1") {
          tagObj._destroy = false
        }
      } else {
        const st = {
          targetId: this.selectedId,
          text: text,
          tag: "category",
          _destroy: false
        }
        this.selectedTags.push(st)
        addHiddenTagInputs(this.selectedTags.length-1, st)
      }
    },

    removeTag(el) {
      const text = el.parentElement.textContent.trim()
      this.selectedTags.find(t => t.text === text)._destroy = "1"
    },

    addNewTag() {
      const tagObj = this.categoryTags.find(t => t.text === this.newTag)
      const selectedTagObj = this.selectedTags.find(t => t.text === this.newTag)
      if (selectedTagObj != null) {
        // tag has already been selected by the user
        this.newTag = ""
        return
      }

      if (tagObj == null) {
        // tag is new
        st = {
          text: this.newTag,
          tag: "category",
          _destroy: false
        }
      } else {
        // tag exists but hasn't been selected yet
        st = {
          targetId: tagObj.id,
          text: tagObj.tag,
          tag: "category",
          _destroy: false
        }
      }
      this.selectedTags.push(st)
      this.newTag = ""
      addHiddenTagInputs(this.selectedTags.length-1, st)
    },
  };
};

function mkElementWOpts(elName, options) {
  const el = document.createElement(elName);
  if (!! options.type) { el.type = options.type }
  if (!! options.name) { el.name = options.name }
  if (!! options.value) { el.value = options.value }
  if (!! options.ix || options.ix == 0) { el.setAttribute('x-model', `selectedTags[${options.ix}]._destroy`) }
  return el;
}

function tagInputName(ix, attr) {
  return `node[anchoring_category_tags_attributes][${ix}][${attr}]`
}

function addHiddenTagInputs(ix, el) {
  const tagEl = mkElementWOpts('input', { type: 'hidden', name: tagInputName(ix, "tag"), value: el.tag })
  const destroyEl = mkElementWOpts('input', { type: 'hidden', name: tagInputName(ix, "_destroy"), value: el._destroy, ix: ix })
  var els = [tagEl, destroyEl]

  // targetId only exists if the category tag is an existing user tag
  if (!! el.targetId) {
    els.push(mkElementWOpts('input', { type: 'hidden', name: tagInputName(ix, "target_id"), value: el.targetId }))
  } else {
    els.push(mkElementWOpts('input', { type: 'hidden', name: tagInputName(ix, "name"), value: el.text }))
  }
  // id only exists for tagDecls that have already been created (i.e. editing a node)
  if (!! el.id) {
    els.push(mkElementWOpts('input', { type: 'hidden', name: tagInputName(ix, "id"), value: el.id }))
  }

  var form = document.getElementById('cat-tags-form')
  els.forEach(el => form.appendChild(el))
}
